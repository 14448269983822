const MIN_SCORE = 14;
const MAX_SCORE = 86;

export function convertScoreToPercentage(score) {
  // Offset by the min. score, so 14 is 0% and 86 is 100%.
  const max = MAX_SCORE - MIN_SCORE;
  const scoreWithOffset = (score || MIN_SCORE) - MIN_SCORE;

  // If for some reason the wellness score is above 86 (shouldn't be), just return 100.
  return Math.min((scoreWithOffset / max) * 100, 100);
}
