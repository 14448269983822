import PropTypes from 'prop-types';
import { Modal as ReactStrapModal, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

const Modal = ({ children, className, ...props }) => {
  return (
    <ReactStrapModal className={`main-modal ${className}`} {...props}>
      {children}
    </ReactStrapModal>
  );
};

export const ModalCloseButton = ({ onClose }) => {
  return (
    <div className="close-button" style={{ textAlign: 'right' }}>
      <FontAwesomeIcon icon={faClose} onClick={onClose} />
    </div>
  );
};

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]),
  className: PropTypes.string,
};

export default Modal;
