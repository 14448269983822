export function getFinancialWellnessMessage(score) {
  if (score <= 25) {
    return 'It looks like your financial life has some room for improvement, but we can help you figure out what to do next. You can update your score at any time via the assessments page.';
  }

  if (score <= 75) {
    return 'It seems like there are some areas where you might improve, but overall you have a good foundation. You can update your score at any time via the assessments page.';
  }

  if (score <= 99) {
    return 'Nice work! Based on your answers, your finances are probably in pretty good shape. You can update your score at any time via the assessments page.';
  }

  return 'Congratulations, your finances seem to be in perfect shape right now. Keep up the great work!';
}
